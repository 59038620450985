import Intro from "views/Intro";
import products from "views/pages/AddProducts/products";
import productsAddForm from "views/pages/AddProducts/productsAddForm";
import brands from "views/pages/brands/brands";
import brandsAddModal from "views/pages/brands/brandsAddModal";
import categories from "views/pages/Categories/categories";
import categoriesAddForm from "views/pages/Categories/categoriesAddForm";
// import categoriesAddModal from "views/pages/Categories/categoriesAddModal";
import colors from "views/pages/Colors/colors";
import colorsAddModal from "views/pages/Colors/colorsAddModal";
import faqs from "views/pages/FAQ/faqs";
import faqsAddModal from "views/pages/FAQ/faqsAddModal";
import gallery from "views/pages/Gallery/gallery";
import inquiries from "views/pages/Inquiry/inquiries";
import markers from "views/pages/Markers/markers";
import markersAddModal from "views/pages/Markers/markersAddModal";
import news from "views/pages/news/news";
import newsAddModal from "views/pages/news/newsAddForm";
import papers from "views/pages/Papers/papers";
import papersAddModal from "views/pages/Papers/papersAddModal";
import settings from "views/pages/Settings/settings";
import sizes from "views/pages/Sizes/sizes";
import sizesAddModal from "views/pages/Sizes/sizesAddModal";
import ArtWorks from "views/pages/ArtWork/artwork";
import AboutUs from "views/pages/Aboutus/aboutus";
import IPData from "views/pages/IPAddress/IPData";
import Analytics from "views/pages/Analytics/Analytics";
import EditorComponent from "views/pages/news/EditorJsComponent";
import howtoorder from "views/pages/HowToOrder/howtoorder";
import whychoosegreen from "views/pages/WhyChooseGreen/whychoosegreen";

const dashboardRoutes = [
  { path: "/intro", component: Intro },
  { path: "/analytics", component: Analytics },

  { path: "/categories", component: categories },
  { path: "/categories/add", component: categoriesAddForm },
  { path: "/categories/edit/:id", component: categoriesAddForm },

  { path: "/brands", component: brands },
  { path: "/brands/add", component: brandsAddModal },

  { path: "/colors", component: colors },
  { path: "/colors/add", component: colorsAddModal },

  { path: "/papers", component: papers },
  { path: "/papers/add", component: papersAddModal },

  { path: "/Tracking", component: IPData },

  { path: "/sizes", component: sizes },
  { path: "/sizes/add", component: sizesAddModal },

  { path: "/markers", component: markers },
  { path: "/markers/add", component: markersAddModal },

  { path: "/news", component: news },
  { path: "/news/add", component: newsAddModal },
  { path: "/news/edit/:id", component: newsAddModal },

  { path: "/faqs", component: faqs },
  { path: "/faqs/add", component: faqsAddModal },

  { path: "/gallerys", component: gallery },

  { path: "/Products", component: products },
  { path: "/Products/add", component: productsAddForm },
  { path: "/Products/edit/:id", component: productsAddForm },

  { path: "/Settings", component: settings },
  { path: "/artwork", component: ArtWorks },
  { path: "/aboutus", component: AboutUs },
  { path: "/why-choose-green", component: whychoosegreen },

  { path: "/how-to-order", component: howtoorder },

  { path: "/Inquiry", component: inquiries }
];

export default dashboardRoutes;
