import React, { useEffect, useState } from "react";
import enhancer from "./enhancer/WhyChooseGreenEnhancer";
import NavigationActions from "redux/navigation/actions";
import { useParams, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { Button } from "reactstrap";
import {
  getWhyChooseGreenDetail,
  updateWhyChooseGreen
} from "services/settingservices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const WhyChooseGreen = props => {
  const {
    token,
    success,
    fetching,
    isFetching,
    error,
    handleBlur,
    setFieldValue,
    values,
    handleSubmit,
    setValues,
    isValid,
    errors,
    touched,
    submitCount
  } = props;
  const [image, setImage] = useState();

  const handleCategorySubmit = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      fetching();
      const formData = new FormData();
      for (const val in values) {
        formData.append(val, values[val]);
      }
      await updateWhyChooseGreen(token, formData || values).then(data => {
        if (data.success) {
          success(data.message);
        } else {
          error(data.message);
        }
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const getWhyChooseGreen = async () => {
    await getWhyChooseGreenDetail(token).then(data => {
      if (data.success) {
        setValues({
          ...values,
          why_choose_green: data.data[0]?.why_choose_green
        });
        success();
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getWhyChooseGreen();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="">
        <div className="mx-3 mt-10">
          <div className="row mb-10 mt-10">
            <div className="col-12 mb-2">
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold"
                }}
              >
                Why Choose Green
              </span>
            </div>
          </div>
        </div>

        <div className="card m-2 p-2">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <ReactQuill
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() <= 1) {
                        setFieldValue("why_choose_green", "");
                      } else {
                        setFieldValue("why_choose_green", content);
                      }
                    }}
                    value={values.why_choose_green}
                    theme={"snow"}
                    placeholder={"Enter Why choose green... "}
                    modules={WhyChooseGreen.modules}
                    formats={WhyChooseGreen.formats}
                    bounds={".app"}
                    id="why_choose_green"
                    name="why_choose_green"
                  />
                </div>
                <div>
                  <Error field="why_choose_green" />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <label>
                Heder Image
                {/* <span className="error-msg">*</span> */}
              </label>
              <div className="">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  id="why_choose_green_image"
                  onBlur={handleBlur}
                  onChange={e => {
                    if (e.target.files[0]) {
                      const [file] = e.target.files;
                      setImage(URL.createObjectURL(file));
                      setFieldValue(
                        "why_choose_green_image",
                        e.target.files[0]
                      );
                    } else {
                      setImage();
                      setFieldValue("why_choose_green_image", " ");
                    }
                  }}
                />

                {image && image !== "" ? (
                  <span className="m-2">
                    <a href={image}>
                      <img
                        src={image}
                        style={{
                          width: "60px",
                          height: "60px",
                          border: "1px #ddd solid",
                          padding: "3px",
                          marginTop: "10px"
                        }}
                      ></img>
                    </a>
                  </span>
                ) : values.logo ? (
                  <span className="m-2">
                    <a
                      href={`${process.env.REACT_APP_BACKEND_URI}uploads/${values.why_choose_green_image}`}
                    >
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URI}uploads/${values.why_choose_green_image}`}
                        style={{
                          width: "60px",
                          height: "60px",
                          border: "1px #ddd solid",
                          padding: "3px",
                          marginTop: "10px"
                        }}
                        alt="why_choose_green_image"
                      ></img>
                    </a>
                  </span>
                ) : (
                  <></>
                )}
                <Error field="why_choose_green_image" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-10">
                <Button
                  className="btn c-primary px-5 add_product_design"
                  onClick={e => handleCategorySubmit(e)}
                  type="button"
                  disabled={isFetching}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WhyChooseGreen.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ],
  clipboard: {
    matchVisual: false
  }
};
WhyChooseGreen.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(WhyChooseGreen);
