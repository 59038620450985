import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Please enter Name")
      .max(50)
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9- ]*[a-zA-Z0-9]$/,
        "Please enter valid Category Name"
      ),
    description: Yup.string().required("Please enter description"),
    category_image: Yup.string().when("parent_id", {
      is: value => (parseInt(value) === 2 ? true : false),
      then: Yup.string().required("Please Select Category Image"),
      otherwise: Yup.string().nullable()
    }),
    title: Yup.string()
      .nullable()
      .required("Please enter title"),
    metaDescription: Yup.string()
      .nullable()
      .required("Please enter meta description"),
    seoKey: Yup.string()
      .nullable()
      .required("Please enter key"),
    canonical: Yup.string()
      .nullable()
      .required("Please enter Canonical")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    name: "",
    description: "",
    parent_id: 0,
    category_image: "",
    metaDescription: "",
    title: "",
    canonical: "",
    seoKey: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
