import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    how_to_order_image: Yup.mixed().optional(),

    how_to_order: Yup.string().required("Please enter how to order")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    how_to_order: "<p></p>",
    how_to_order_image: " "
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
