import { api, handleResponse, handleError } from "./apiServices";

export const getsetting = token =>
  api(token)
    .get("/setting/list")
    .then(handleResponse)
    .catch(handleError);

export const updatesetting = (token, data) =>
  api(token)
    .put(`/setting/update`, data)
    .then(handleResponse)
    .catch(handleError);

export const getAboutUs = token =>
  api(token)
    .get("/setting/get-aboutus")
    .then(handleResponse)
    .catch(handleError);

export const getWhyChooseGreenDetail = token =>
  api(token)
    .get("/setting/why-choose-green")
    .then(handleResponse)
    .catch(handleError);

export const getHowToOrderDetail = token =>
  api(token)
    .get("/setting/how-to-order")
    .then(handleResponse)
    .catch(handleError);

export const updateHowToOrder = (token, data) =>
  api(token)
    .put(`/setting/update-how-to-order`, data)
    .then(handleResponse)
    .catch(handleError);

export const updateWhyChooseGreen = (token, data) =>
  api(token)
    .put(`/setting/update-why-choose-green`, data)
    .then(handleResponse)
    .catch(handleError);

export const updateAboutUs = (token, data) =>
  api(token)
    .put(`/setting/update-aboutus`, data)
    .then(handleResponse)
    .catch(handleError);
