import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    why_choose_green_image: Yup.mixed().optional(),

    why_choose_green: Yup.string().required("Please enter why choose green")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    why_choose_green: "<p></p>",
    why_choose_green_image: " "
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
