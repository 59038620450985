import React, { useState, useEffect } from "react";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import {
  getAnalyticsFridgeMagnets,
  getAnalyticsPrintingProducts,
} from "services/IPAddServices";
import "../../../assets/css/dash.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

const Analytics = (props) => {
  const { token, success, error, fetching } = props;
  const [vst_data, setVstdata] = useState({});
  const [prt_data, setPrt_data] = useState({});

  //eslint-disable-next-line
  const [refresh, toggleRefresh] = useState(true);
  const [date, setDate] = useState({ startDate: "", endDate: "" });

  const onStartDateChange = (date_val) => {
    setDate({ startDate: date_val });
  };

  const onEndDateChange = (date_val) => {
    setDate({ ...date, endDate: date_val });
  };

  const getDashboarddata = async (data) => {
    fetching();
    await getAnalyticsFridgeMagnets(token, data).then((data) => {
      if (data.success) {
        setVstdata(data.data);
        success();
        toggleRefresh(false);
      } else {
        error(data.message);
      }
    });
    await getAnalyticsPrintingProducts(token, data).then((data) => {
      if (data.success) {
        setPrt_data(data.data);
        success();
        toggleRefresh(false);
      } else {
        error(data.message);
      }
    });
  };

  useEffect(() => {
    getDashboarddata();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      date.startDate &&
      date.startDate !== "" &&
      date.endDate &&
      date.endDate !== ""
    ) {
      getDashboarddata(date);
    }
    //eslint-disable-next-line
  }, [date]);

  return (
    <div className="card m-2 p-2">
      <div className="container-fluid">
        <div className="row title-sec align-items-center mb-20">
          <div className="col-sm headline border-bottom py-2">Analytics</div>
          <div className="col-sm-auto ml-auto">
            {/* <button className="btn btn-blue" onClick={() => setOpenModal(true)}>
            <Plus className="mr-2" /> Add Color
          </button> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-10 mb-10 pb-20">
            <div className="">
              {/* <div className="card border-0"> */}
              <div className=" dash_crd_bdy">
                <span className="dash_title ">Total Visitors</span>
                <span className="dash_crd_txt">{vst_data.monthly_Visitor}</span>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* <div className="col-sm-6 mt-10 mb-10">
            <div className="card border-0">
              <div className="card-body dash_crd_bdy">
                <h5 className="card-title dash_title">Monthly Visitors</h5>
                <p className="card-text dash_crd_txt">
                  {vst_data.monthly_visitor}
                </p>
              </div>
            </div>
          </div> */}

          <div className="col-12 mb-10 border-top pt-10">
            <span className="Step_font"> Steps Detail </span>
          </div>
          <div className="col-12 mb-10">
            <div className="row">
              <div className="col-xl-3 col-md-3 col-sm-4 col-6 w-100">
                <div>
                  <label> Start Date</label>
                </div>
                <div className="w-100">
                  <DatePicker
                    className="form_datepicker w-100"
                    selectsStart
                    startDate={date?.startDate}
                    endDate={date?.endDate}
                    maxDate={new Date()}
                    onChange={(date) => onStartDateChange(date)}
                    selected={date?.startDate}
                    dateFormat="dd-MM-yy"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-4 col-6 w-100">
                <div>
                  <label> End Date</label>
                </div>
                <div className="w-100">
                  <DatePicker
                    className="form_datepicker w-100"
                    selectsStart
                    startDate={date?.startDate}
                    endDate={date?.endDate}
                    // maxDate={endDate}
                    minDate={date?.startDate}
                    onChange={(date) => onEndDateChange(date)}
                    selected={date?.endDate}
                    dateFormat="dd-MM-yy"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-10">
            <h5 className="main_cat">FridgeMagnets Data </h5>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10 h-100">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Product Select (step 1)
                </h5>
                <div className="card-text">
                  <span>{vst_data.step_1}</span>

                  <span className="perc_border">
                    ({vst_data.first_step_perc} %)
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Quantity Select (step 2)
                </h5>

                <span> {vst_data.step_2}</span>

                <span className="perc_border">
                  ({vst_data.second_step_perc} %)
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Artwork Select (step 3)
                </h5>

                <span> {vst_data.step_3}</span>

                <span className="perc_border">
                  ({vst_data.third_step_perc} %)
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  {" "}
                  Inquiry Submit
                </h5>

                <span> {vst_data.step_4}</span>

                <span className="perc_border">
                  ({vst_data.form_filled_in_perc} %)
                </span>
              </div>
            </div>
          </div>

          {/* PRINTING DATA */}
          {/* 
          <div className="col-12 mb-10">
            <h5 className="main_cat">Printing Products Data </h5>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Product Select (step 1)
                </h5>
                <div className="card-text">
                  <span>{prt_data.step_1}</span>

                  <span className="perc_border">
                    ({prt_data.first_step_perc} %)
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Quantity Select (step 2)
                </h5>

                <span> {prt_data.step_2}</span>

                <span className="perc_border">
                  ({prt_data.second_step_perc} %)
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  Artwork Select (step 3)
                </h5>

                <span> {prt_data.step_3}</span>

                <span className="perc_border">
                  ({prt_data.third_step_perc} %)
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  {" "}
                  Color Selected
                </h5>

                <span> {prt_data.color}</span>

                <span className="perc_border">({prt_data.color_perc} %)</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  {" "}
                  Paper Size Selected
                </h5>

                <span> {prt_data.page_size}</span>

                <span className="perc_border">({prt_data.page_perc} %)</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 mb-10">
            <div className="card step_body">
              <div className="card-body step_pad">
                <h5 className="card-title dash_visitor_title">
                  {" "}
                  Inquiry Submit
                </h5>

                <span> {prt_data.step_4}</span>

                <span className="perc_border">
                  ({prt_data.form_filled_in_perc} %)
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { success, error, fetching, setuser })
)(Analytics);
